<!-- 系统配置 -->
<template>
  <div class="root">
    <el-tabs>
      <!-- 系统配置 -->
      <el-tab-pane label="系统配置">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="公司logo">
            <el-upload
              class="uploader"
              :action="uploadurl"
              :show-file-list="false"
              :on-success="onSuccess"
              :before-upload="beforeUpload"
            >
              <img v-if="form.logo" :src="form.logo" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="form.company" />
          </el-form-item>
          <el-form-item label="公司网址">
            <el-input v-model="form.company_url" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <!-- 邮箱配置 -->
      <el-tab-pane label="邮箱配置">
        <el-form ref="form" :model="mailbox" label-width="100px">
          <el-form-item label="发件人邮箱">
            <el-input v-model="mailbox.mail_username" />
          </el-form-item>
          <el-form-item label="秘钥">
            <el-input v-model="mailbox.mail_password" />
          </el-form-item>
          <el-form-item label="发件人名称">
            <el-input v-model="mailbox.send_user" />
          </el-form-item>
          <el-form-item label="邮件标题">
            <el-input v-model="mailbox.mail_title" />
          </el-form-item>
          <el-form-item label="收件人邮箱">
            <el-input v-model="mailbox.mail_recipient" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit_modify">修改</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

    </el-tabs>

  </div>
</template>

<script>
import { getConfig, updateConfig, getEmail, updateEmail } from '@/api/config'
export default {
    data() {
        return {
            uploadurl: process.env.VUE_APP_BASE_API + '/admin/upload',
            form: {
                company: '',
                logo: '',
                company_url: ''
            },
            rules: {
                company: [
                    { required: true, message: '请输入公司名称', trigger: 'change' }
                ],
                logo: [
                    { required: true, message: '请上传logo', trigger: 'change' }
                ],
                company_url: [
                    { required: true, message: '请输入公司地址', trigger: 'change' }
                ]
            },
            // 邮箱配置
            mailbox: {
                mail_username: '',
                mail_password: '',
                send_user: '',
                mail_title: '',
                mail_recipient: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getConfigInfo()
        this.getEmailInfo()
    },
    mounted() {},
    methods: {
        async getConfigInfo() {
            const res = await getConfig()
            if (res.code === 0) {
                this.form = res.list
            }
        },
        onSubmit() {
            updateConfig({ ...this.form }).then(res => {
                if (res.code === 0) {
                    this.getConfigInfo()
                }
            })
        },
        onSuccess(res, file) {
            if (res.code === 0) {
                this.form.logo = res.data.src
            }
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg' || 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },
        // 邮箱配置显示
        async getEmailInfo() {
            const res = await getEmail()
            if (res.code === 0) {
                this.mailbox = res.data
            }
        },
        // 邮箱修改
        onSubmit_modify() {
            updateEmail({ ...this.mailbox }).then(res => {
                if (res.code === 0) {
                    this.getEmailInfo()
                }
            })
        }

    }
}
</script>

<style lang='scss' scoped>
.root{
    .el-form{
        width: 500px;
        margin-top: 20px;
    }
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }

}
</style>
