import request from '@/utils/request'

// 获取配置信息
export const getConfig = (params) => {
    return request.get('admin/getConfig', { params })
}

// 修改配置信息
export const updateConfig = (params) => {
    return request.post('admin/getConfig', params)
}

// 获取邮箱配置
export const getEmail = (params) => {
    return request.get('admin/emailComfig', params)
}

// 修改邮箱配置
export const updateEmail = (params) => {
    return request.post('admin/emailComfig', params)
}

// 修改账号密码
export const updatepwd = (params) => {
    return request.post('admin/updatepwd', params)
}
